import React from 'react';
import { Box, Typography as Text } from '@mui/material';

import LogoMobile from '../../../assets/LogoMobile';
import Logo from '../../../assets/Logo';

interface HeaderProps {
  matchMobile: boolean;
}

const Header = ({ matchMobile }: HeaderProps) => {
  return (
    <Box
      display="flex"
      position={{ xs: 'relative', md: 'absolute' }}
      top={{ md: '68px' }}
      gap={{ xs: 1, md: 2 }}
      left={{ md: '85px' }}
      alignItems="center"
    >
      {matchMobile ? <LogoMobile /> : <Logo />}

      <Text
        fontSize={{ xs: '3rem', md: '4rem' }}
        sx={(theme) => ({
          color: 'secondary.main',
          cursor: 'default',
          lineHeight: 1,
        })}
        fontFamily={'Readex Pro, sans-serif'}
        fontWeight={700}
      >
        Yethos
      </Text>
    </Box>
  );
};

export default Header;
