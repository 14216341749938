import React from 'react';
import { Box, Typography as Text } from '@mui/material';

const TextContent = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ xs: 'flex-start' }}
      justifyContent="center"
      textAlign={{ xs: 'start' }}
      gap={2}
      paddingRight={2}
    >
      <Text
        fontWeight={400}
        fontSize="1rem"
        lineHeight={1.4}
        sx={(theme) => ({
          color: 'secondary.main',
          cursor: 'default',
        })}
      >
        Uma nova plataforma de comunidades online para conectar e compartilhar
        interesses em um ambiente inclusivo e colaborativo.
      </Text>
      <Text
        fontWeight={700}
        marginTop={0}
        lineHeight={1.4}
        fontSize={{ xs: '1rem', sm: '1.5rem' }}
        sx={(theme) => ({
          color: 'secondary.main',
          cursor: 'default',
        })}
      >
        Inscreva-se para receber atualizações sobre o lançamento da Yethos.
      </Text>
    </Box>
  );
};

export default TextContent;
