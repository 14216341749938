import React from 'react';
import { get } from 'lodash';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  InputBase,
  useMediaQuery,
  useTheme,
  Typography as Text,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';

interface EmailInputProps {
  success: boolean;
  sendLogEvent: () => void;
}

const EmailInput = ({ success, sendLogEvent }: EmailInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <>
      <Box width="100%" maxWidth="530px">
        <Text
          sx={(theme) => ({
            color: 'secondary.main',
            cursor: 'default',
          })}
          fontSize=".875rem"
        >
          Não se preocupe, não enviamos spam!
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth="530px"
        padding={{ xs: '4px 4px 4px 8px', xl: '4px 4px 4px 24px' }}
        sx={(theme) => ({
          background: 'rgb(232, 240, 254)',
          borderRadius: '6px',
        })}
      >
        <InputBase
          error={!!`${get(errors, 'email.message', '')}`}
          placeholder={'Coloque seu endereço de e-mail'}
          {...register('email')}
          type="email"
          size="small"
          // disabled={success}
          onBlur={sendLogEvent}
          sx={(theme) => ({
            width: '280px',
            '& .MuiInputBase-input': {
              padding: { xs: 1, xl: 0 },
              fontStyle: 'italic',
              fontWeight: 500,
            },
          })}
        />
        <Button
          sx={(theme) => ({
            fontSize: '1.125rem',
            display: { xs: 'none', md: 'flex' },
            padding: '10px 24px',
            textTransform: 'none',
            borderRadius: '6px',
          })}
          type="submit"
          variant="contained"
        >
          {match ? <SendIcon sx={{ color: 'white' }} /> : 'Solicitar cadastro'}
        </Button>
      </Box>
      <Button
        sx={(theme) => ({
          maxWidth: '530px',
          fontSize: '1.125rem',
          display: { xs: 'flex', md: 'none' },
          textTransform: 'none',
          borderRadius: '6px',
          marginTop: 1,
          [theme.breakpoints.down('sm')]: {
            height: '56px',
          },
        })}
        type="submit"
        fullWidth
        variant="contained"
      >
        Solicitar cadastro
      </Button>
    </>
  );
};

export default EmailInput;
