import React from 'react';

const LogoMobile = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM28.7101 26.3759L28.5828 26.5055V26.6887V34.7719C28.5828 37.4596 26.4409 39.6384 23.7988 39.6384C21.1566 39.6384 19.0147 37.4596 19.0147 34.7719V26.2924V26.109L18.8874 25.9794L11.5487 18.5143C14.3563 20.5956 18.959 22.4671 23.9677 17.3828L23.9936 17.4092L24.3011 17.0964L31.4866 9.78696C33.3548 7.88646 36.384 7.88646 38.2522 9.78696C40.1206 11.6875 40.1206 14.7688 38.2522 16.6693L28.7101 26.3759ZM17.1284 9.17432L23.9936 16.1579L30.8716 9.1613C33.0796 6.91525 36.6593 6.91525 38.8673 9.1613C41.0752 11.4073 41.0752 15.0488 38.8673 17.2949L29.4526 26.8721V34.7719C29.4526 37.9482 26.9213 40.5233 23.7988 40.5233C20.6762 40.5233 18.1448 37.9482 18.1448 34.7719V26.4755L9.13273 17.308C6.92477 15.0619 6.92477 11.4204 9.13273 9.17432C11.3407 6.92827 14.9206 6.92827 17.1284 9.17432Z"
        fill="url(#paint0_linear_2909_4317)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2909_4317"
          x1="24"
          y1="0"
          x2="24"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DD6B20" />
          <stop offset="0.588542" stop-color="#DD6B20" />
          <stop offset="1" stop-color="#F6AD55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoMobile;
