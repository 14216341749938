import React from 'react';

const Logo = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40Z"
        fill="white"
      />
      <path
        d="M47.6379 44.1758V57.9531C47.6379 62.4326 44.0681 66.064 39.6645 66.064C35.2609 66.064 31.6912 62.4326 31.6912 57.9531V43.515L19.2478 30.8571C23.9273 34.3259 31.5985 37.4451 39.9461 28.9713L39.9894 29.0153L52.4776 16.3116C55.5915 13.1441 60.6399 13.1441 63.7537 16.3116C66.8675 19.4791 66.8675 24.6146 63.7537 27.7821L47.6379 44.1758Z"
        fill="url(#paint0_linear_2730_52950)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40ZM28.5475 15.2905L39.9893 26.9298L51.4526 15.2688C55.1325 11.5254 61.0989 11.5254 64.7788 15.2688C68.4587 19.0122 68.4587 25.0814 64.7788 28.8249L49.0876 44.7867V57.9531C49.0876 63.2471 44.8687 67.5387 39.6645 67.5387C34.4603 67.5387 30.2415 63.2471 30.2415 57.9531V44.1258L15.2212 28.8466C11.5413 25.1032 11.5413 19.0339 15.2212 15.2905C18.9012 11.5471 24.8675 11.5471 28.5475 15.2905Z"
        fill="url(#paint1_linear_2730_52950)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2730_52950"
          x1="40"
          y1="0"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD6B20" />
          <stop offset="0.588542" stopColor="#DD6B20" />
          <stop offset="1" stopColor="#F6AD55" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2730_52950"
          x1="40"
          y1="0"
          x2="40"
          y2="80"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DD6B20" />
          <stop offset="0.588542" stopColor="#DD6B20" />
          <stop offset="1" stopColor="#F6AD55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
