import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { YETHOS_THEME_MUI } from '../theme/theme.constants';

interface ThemeProviderMuiProps {
  children: React.ReactNode;
}

const ThemeProviderMui: React.FC<ThemeProviderMuiProps> = ({ children }) => {
  return <ThemeProvider theme={YETHOS_THEME_MUI}>{children}</ThemeProvider>;
};

export default ThemeProviderMui;
