import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import FrameWidth900 from '../../../assets/width900.png';

type FormData = { email: string };

const schema = yup.object({
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
});

interface FormMobileProps {
  onSubmit: (formData: FormData) => void;
  children: JSX.Element;
}

const FormMobile = ({ onSubmit, children }: FormMobileProps) => {
  const formMethods = useForm<FormData>({ resolver: yupResolver(schema) });
  const { handleSubmit } = formMethods;
  return (
    <FormProvider {...formMethods}>
      <Box>
        <img
          src={FrameWidth900}
          width="100%"
          alt="img yethos mobile"
          style={{ objectFit: 'contain' }}
        />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={(theme) => ({
          paddingBottom: 2,
          gap: 1,
          width: '100%',
        })}
      >
        <Box
          component="form"
          display="flex"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: { width: '100%' },
          })}
          justifyContent="flex-start"
          flexDirection="column"
          alignSelf={{ xs: 'center', md: 'flex-start' }}
          alignItems="center"
          onSubmit={handleSubmit(onSubmit)}
          marginTop={{ xs: 0, xl: 7.5 }}
          gap={0.5}
        >
          {children}
        </Box>
      </Box>
    </FormProvider>
  );
};

export default FormMobile;
