import { createTheme } from '@mui/material/styles';

export let YETHOS_THEME_MUI = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#2D3748',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#2D3748',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#2D3748',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#E2E8F0',
      main: '#DD6B20',
      dark: '#C05621',
      contrastText: '#F7FAFC',
    },
    secondary: {
      light: '#EDF2F7',
      main: '#2D3748',
      dark: '#E2E8F0',
      contrastText: '#F7FAFC',
    },
    info: {
      main: '#EDF2F7',
      dark: '#718096',
      contrastText: '#CBD5E0',
      light: '#F7FAFC',
    },
  },
  typography: {
    fontFamily: 'Inter',
    h4: {
      fontSize: '1.4rem',
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
      },
    },
  },
  yethosCustoms: {
    shadows: {
      // TODO: name these shadows better by depth instead (Felipe)
      card: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      cardHover: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
    },
  },
});

YETHOS_THEME_MUI.components = {
  MuiButton: {
    styleOverrides: {
      outlined: ({ theme }) => ({
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.dark,
        '&:hover': {
          borderColor: theme.palette.secondary.dark,
          backgroundColor: theme.palette.secondary.light,
        },
      }),
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.secondary.light,
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      }),
      text: ({ theme }) => ({
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }),
      textSecondary: ({ theme }) => ({
        color: theme.palette.info.main,
        '&:hover': {
          backgroundColor: '#4A5568',
        },
      }),
    },
  },
};
