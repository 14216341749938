// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJYJbHx0GHJkUrW2yCyFy4HBsGJcV_eq4",
  authDomain: "yethos-82019.firebaseapp.com",
  projectId: "yethos-82019",
  storageBucket: "yethos-82019.appspot.com",
  messagingSenderId: "168114720390",
  appId: "1:168114720390:web:4effff40fe3cbe1e8f4b07",
  measurementId: "G-0Q8P45CRMW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
