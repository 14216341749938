import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { logEvent } from 'firebase/analytics';
import {
  Box,
  Typography as Text,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import { analytics, db } from '../../firebase/firebase.config';
import FramePNGYethos from '../../assets/FramePNGYethos.png';

import EmailInput from './components/EmailInput';
import Header from './components/Header';
import TextContent from './components/TextContent';
import FormMobile from './components/FormMobile';
import EmailInputEndPage from './components/EmailInputEndPage';

const schema = yup.object({
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
});
type FormData = { email: string };

const Home = () => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));
  const [success, setSuccess] = React.useState(false);
  const formMethods = useForm<FormData>({ resolver: yupResolver(schema) });
  const { handleSubmit, reset } = formMethods;
  const sendLogEvent = () => logEvent(analytics, 'on_blur');

  const registerEmail = async (email: string) => {
    await addDoc(collection(db, 'waiting-list'), {
      email,
      status: 'waiting',
      created_at: Timestamp.now(),
    });
  };

  const onSubmit = (formData: FormData) => {
    registerEmail(formData.email);
    setSuccess(true);
    reset();
  };

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'white',
        borderRadius: 0,
      })}
      minHeight="100vh"
      display="grid"
      gridTemplateColumns={{
        xs: '1.4fr 1fr 1fr',
        xl: '728px 1fr 1fr',
      }}
      gridTemplateRows="auto 1fr"
    >
      <Box
        display="flex"
        flexDirection={{ xs: 'column' }}
        position="relative"
        alignItems="center"
        gap={4}
        padding={{ xs: '24px 40px 0px 40px', md: 0 }}
        gridArea="1/1/2/4"
      >
        <Header matchMobile={matchMobile} />
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        })}
        gridArea="2/2/3/4"
        display="flex"
        justifyContent="center"
        paddingRight={{ xs: 0 }}
        paddingTop={{ xs: '125px', xl: '25px' }}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          width="100%"
          maxWidth="1033px"
        >
          <img
            src={FramePNGYethos}
            width="100%"
            alt="img yethos"
            style={{ objectFit: 'contain' }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridArea={{ xs: '2/1/3/4', md: '2/1/3/2' }}
        gap={{ xs: 5, md: 10 }}
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
        paddingTop={{ xs: 5, md: '205px', xl: '285px' }}
        paddingLeft={{ xs: 2, md: '105px', xl: '165px' }}
        paddingRight={{ xs: 2, md: 0 }}
      >
        <TextContent />
        <FormProvider {...formMethods}>
          <Box
            component="form"
            display="flex"
            width="100%"
            maxWidth="530px"
            justifyContent={'flex-start'}
            flexDirection="column"
            alignItems="center"
            alignSelf={{ xs: 'center', md: 'flex-start' }}
            onSubmit={handleSubmit(onSubmit)}
            gap={0.5}
          >
            <EmailInput sendLogEvent={sendLogEvent} success={success} />
            {success && (
              <Text fontSize=".875rem" sx={{ color: '#38A169' }}>
                Obrigado por se inscrever! Você receberá atualizações sobre o
                lançamento da Yethos em breve.
              </Text>
            )}
          </Box>
        </FormProvider>
        {matchMD && (
          <FormMobile onSubmit={onSubmit}>
            <>
              <EmailInputEndPage
                sendLogEvent={sendLogEvent}
                success={success}
              />
              {success && (
                <Text fontSize=".875rem" sx={{ color: '#38A169' }}>
                  Obrigado por se inscrever! Você receberá atualizações sobre o
                  lançamento da Yethos em breve.
                </Text>
              )}
            </>
          </FormMobile>
        )}
      </Box>
    </Box>
  );
};

export default Home;
