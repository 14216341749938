import React from 'react';

import './App.css';
import Home from './screens/landing-page/Home';

function App() {
  return <Home />;
}

export default App;
